/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
    height: 100%;
    width: 100%;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
